import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { BlurhashCanvas } from "react-blurhash"

const Img = React.forwardRef(({
  src,
  alt,
  width,
  height,
  loading,
  retina,
  hash,
  className,
  children,
  ...props
}, ref) => {

  // let observer = new IntersectionObserver(
  //   (entries, observer) => {
  //     entries.forEach(entry => {
  //       if (entry.intersectionRatio > 0) {
  //         console.log('in view')
  //       } else {
  //         console.log('out of view')
  //       }
  //     })
  //   },
  //   {
  //     root       : null,
  //     rootMargin : '0%',
  //     threshold  : 0
  //   }
  // );


  // Constructors and Lifecycle
  // --------------------------
  // 01. The first argument is fired after browser layout and paint. The
  //     second argument is an array of values, which when empty only fires
  //     once, making it similar to the componentDidMount() method.
  // 02. When you return a function in the callback of useEffect(), the
  //     returned function will be called before the component is removed,
  //     making it similar to the componentWillUnmount() method.

  const elImg                  = useRef(null)
  const [theWidth, setWidth]   = useState(width)
  const [theHeight, setHeight] = useState(height)
  const classNames             = ['hcc-image']
  // const placeholder            = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyVpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDYuMC1jMDAyIDc5LjE2NDM1MiwgMjAyMC8wMS8zMC0xNTo1MDozOCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIxLjEgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RjYyNEY0MEU3OEU2MTFFQUE4MTNGQUM0MkNCMjFDODMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RjYyNEY0MEY3OEU2MTFFQUE4MTNGQUM0MkNCMjFDODMiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpBRTE2NEM3Rjc4ODIxMUVBQTgxM0ZBQzQyQ0IyMUM4MyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpBRTE2NEM4MDc4ODIxMUVBQTgxM0ZBQzQyQ0IyMUM4MyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pn4C4EcAAAAQSURBVHjaYvj//z8DQIABAAj8Av7bok0WAAAAAElFTkSuQmCC'
  // const placeholder            = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
  // const placeholder            = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw'
  // const placeholder            = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=='
  // const placeholder            = "data:image/svg+xml;charset=utf-8,%3Csvg xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg' viewBox%3D'0 0 200 150'%2F%3E"

  if ( className ) {
    classNames.push(className)
  }

  useEffect(() => { // 01
    let dummy = new Image()
    dummy.src = src

    dummy.onload = () => {
      if ( theWidth === null ) {
        setWidth(retina ? dummy.naturalWidth / 2 : dummy.naturalWidth)
      }
      if ( theHeight === null ) {
        setHeight(retina ? dummy.naturalHeight / 2 : dummy.naturalHeight)
      }
      dummy = null
    }

    // observer.observe(elImg.current)
  }, [src, retina, theWidth, theHeight])

  useEffect(() => { // 02
    return () => {
      setWidth(null)
      setHeight(null)
      // observer.disconnect()
    }
  }, [])


  // Output
  // ------

  return (
    <span className={classNames.join(' ')} {...props}>
      {hash && <BlurhashCanvas hash={hash} width={32} height={32} punch={1}/>}
      <img
        src     = {src}
        alt     = {alt}
        width   = {theWidth}
        height  = {theHeight}
        loading = {loading}
        ref     = {ref || elImg}
      />
      {children}
    </span>
  )
})

Img.defaultProps = {
  src       : null,
  alt       : null,
  width     : null,
  height    : null,
  loading   : "eager",
  retina    : true,
  hash      : null,
  className : null,
  children  : null,
}

Img.propTypes = {
  src       : PropTypes.string,
  alt       : PropTypes.string,
  width     : PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height    : PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loading   : PropTypes.string,
  retina    : PropTypes.bool,
  hash      : PropTypes.string,
  className : PropTypes.string,
  children  : PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

export default Img
